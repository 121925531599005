import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import { getRestaurantThunk, changeOnlineStatusThunk, updateSMSNotificationThunk } from "../../../store/restaurants";
import { fetchHours } from "../../../store/Hours";
import Hours from "./Hours";

function StopOrdering () {
    const dispatch = useDispatch();
    const id = useSelector(state => state.session.user.restaurant_id);
    const restaurant = useSelector(state => state.restaurant.restaurant);
    const [stoppedOrdering, setStoppedOrdering] = useState(false);
    const [phone, setPhone] = useState('');


    useEffect(() => { 
        dispatch(getRestaurantThunk(id))
    }, [dispatch])

    useEffect(() => {
        if (restaurant) {
            setStoppedOrdering(restaurant.stop_order)
            setPhone(restaurant.sms_noti_phone || '')
        }
    
    },[restaurant, ])

    const handleGoOffline = () => {
        swal({
            title: "Do you want to go offline?",
            text: "Your Restaurant will not be able to receive orders.",
            icon: "warning",
            buttons: ["Cancel", "Go Offline"],
            dangerMode: true,
        }).then((willGoOffline) => {
            if (willGoOffline) {
                dispatch(changeOnlineStatusThunk(id))
            }
        });
    };

    const handleGoOnline = () => {
        swal({
            title: "Do you want to go online?",
            text: "You will be able to receive orders again.",
            icon: "info",
            buttons: ["Cancel", "Go Online"],
        }).then((willGoOnline) => {
            if (willGoOnline) {
                dispatch(changeOnlineStatusThunk(id))
            }
        });
    };

    const handlePhoneChange = (e) => {
        setPhone(e.target.value)
    }

    const updatePhone = async () => { 
        await dispatch(updateSMSNotificationThunk(id, phone))
        .then(() => {
            dispatch(getRestaurantThunk(id))
            swal("Phone Number Updated", {
                icon: "success",
            });
        })
    }

    

    return (
        <div>
            {stoppedOrdering ? (
                <h2 className="text-danger">You Restaurant Is Offline</h2>
            ) : (
                <h2 className="text-success">You Restaurant Is Online</h2>
            )}
            {stoppedOrdering ? (
                <button className="btn btn-shadow btn-light text-danger" onClick={handleGoOnline}>Go Online</button>
            ) : (
                <button className="btn btn-shadow btn-light text-success" onClick={handleGoOffline}>Go Offline</button>
            )}
            <Hours id={id}/>


            <div className="my-3">
                <h2>Update Phone Number for SMS Notifications</h2>
                <p>Enter the phone number you want to receive SMS notifications for new orders.</p>
                <p>Format,no dash, space, example:9172419283</p>
                <input placeholder="2222222222" type="text" value={phone} onChange={handlePhoneChange}/>
                <button className="btn btn-shadow btn-light" onClick={updatePhone}>Update Phone</button>
            </div>
        </div>
    );
}

export default StopOrdering;
