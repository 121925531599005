import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchHours,
  updateRegularHoursThunk,
  updateTimezoneThunk,
} from "../../../store/Hours";
import { Row, Col, Card, Accordion, Form, Button } from "react-bootstrap";

function Hours({ id }) {
  const dispatch = useDispatch();
  const hours = useSelector((state) => state?.hours?.hours);
  const [regularOpenHours, setRegularOpenHours] = useState({});
  const [regularCloseHours, setRegularCloseHours] = useState({});
  const [selectedTimeZone, setSelectedTimeZone] = useState("");

  useEffect(() => {
    dispatch(fetchHours(id));
  }, [dispatch, id]);

  const handleRegularHoursUpdate = (day) => {
    dispatch(
      updateRegularHoursThunk(
        id,
        day,
        regularOpenHours[day],
        regularCloseHours[day]
      )
    );
  };

  const handleCloseRegularHours = (day) => {
    dispatch(updateRegularHoursThunk(id, day, "CLOSED", "CLOSED"));
  };

  const handleTimeZoneChange = (e) => {
    setSelectedTimeZone(e.target.value);
    dispatch(updateTimezoneThunk(id, e.target.value));
  };

  const regularOpeningHours = [
    { title: "Monday", text: hours?.monday, day: "monday" },
    { title: "Tuesday", text: hours?.tuesday, day: "tuesday" },
    { title: "Wednesday", text: hours?.wednesday, day: "wednesday" },
    { title: "Thursday", text: hours?.thursday, day: "thursday" },
    { title: "Friday", text: hours?.friday, day: "friday" },
    { title: "Saturday", text: hours?.saturday, day: "saturday" },
    { title: "Sunday", text: hours?.sunday, day: "sunday" },
  ];
  const timeZones = ["EST", "CST", "PST", "MST"];

  return (
    <Fragment>
      <Row>
        <Col xl="12" className="mt-4">
          <Card>
            <Card.Header className="d-block card-header">
              <Card.Title>Regular Opening Hours</Card.Title>
              <Card.Text className="m-0 subtitle">Monday - Sunday</Card.Text>

              <Form.Group className="mt-3">
                <Form.Label className="text-danger">
                  Current time zone: {hours?.time_zone || selectedTimeZone}
                </Form.Label>
                <Form.Select
                  value={selectedTimeZone}
                  onChange={handleTimeZoneChange}
                  aria-label="Select Time Zone"
                >
                  {timeZones.map((zone, index) => (
                    <option key={index} value={zone}>
                      {zone}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Card.Header>
            <Card.Body className="card-body">
              <Accordion
                className="accordion accordion-primary"
                defaultActiveKey="0"
              >
                {regularOpeningHours.map((d, i) => (
                  <Accordion.Item
                    className="accordion-item"
                    key={i}
                    eventKey={`${i}`}
                  >
                    <Accordion.Header className="accordion-header rounded-lg">
                      {d.title} - ({d.text || "Closed"})
                    </Accordion.Header>
                    <Accordion.Collapse eventKey={`${i}`}>
                      <div className="accordion-body">
                        <Form>
                          <Form.Group className="mb-3">
                            <Form.Label>Opening Time</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="HH:mm"
                              maxLength={5} // Restrict input to 5 characters (e.g., 10:00)
                              value={regularOpenHours[d.day] || ""}
                              onChange={(e) => {
                                const input = e.target.value.replace(
                                  /[^0-9]/g,
                                  ""
                                ); // Allow only numbers
                                let formattedInput = input;

                                // Automatically insert a colon after the second digit
                                if (input.length > 2) {
                                  formattedInput =
                                    input.slice(0, 2) + ":" + input.slice(2, 4);
                                }

                                setRegularOpenHours({
                                  ...regularOpenHours,
                                  [d.day]: formattedInput,
                                });
                              }}
                            />
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Label>Closing Time</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="HH:mm"
                              maxLength={5}
                              value={regularCloseHours[d.day] || ""}
                              onChange={(e) => {
                                const input = e.target.value.replace(
                                  /[^0-9]/g,
                                  ""
                                );
                                let formattedInput = input;

                                if (input.length > 2) {
                                  formattedInput =
                                    input.slice(0, 2) + ":" + input.slice(2, 4);
                                }

                                setRegularCloseHours({
                                  ...regularCloseHours,
                                  [d.day]: formattedInput,
                                });
                              }}
                            />
                          </Form.Group>
                          <Button
                            variant="primary"
                            onClick={() => handleRegularHoursUpdate(d.day)}
                          >
                            Save
                          </Button>{" "}
                          <Button
                            variant="secondary"
                            onClick={() => handleCloseRegularHours(d.day)}
                          >
                            Close today
                          </Button>
                        </Form>
                      </div>
                    </Accordion.Collapse>
                  </Accordion.Item>
                ))}
              </Accordion>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
}

export default Hours;
