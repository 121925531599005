import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import ordersReducer from "./orders";
import sessionReducer from "./session";
import itemsReducer from "./items";
import categoriesReducer from "./categories";
import attributesReducer from "./attributes";
import feesReducer from "./fees";
import notificationReducer from "./notifications";
import restaurantReducer from "./restaurants";
import stripeReducer from "./stripe";
import customersReducer from "./customers";
import waitlistsReducer from "./waitlists";
import couponsReducer from "./coupons";
import freeitemsReducer from "./freeitems";
import printersReducer from "./printers";
import rewardsReducer from "./rewards";
import hoursReducer from "./Hours";

const rootReducer = combineReducers({
  // add reducer functions here
  session: sessionReducer,
  orders: ordersReducer,
  items: itemsReducer,
  categories: categoriesReducer,
  attributes: attributesReducer,
  fees: feesReducer,
  notifications: notificationReducer,
  restaurant: restaurantReducer,
  stripe: stripeReducer,
  customers: customersReducer,
  waitlists: waitlistsReducer,
  coupons: couponsReducer,
  freeItems: freeitemsReducer,
  printers: printersReducer,
  rewards: rewardsReducer,
  hours: hoursReducer
});

let enhancer;

if (process.env.NODE_ENV === "production") {
  enhancer = applyMiddleware(thunk);
} else {
  const logger = require("redux-logger").default;
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  enhancer = composeEnhancers(applyMiddleware(thunk, logger));
}

const configureStore = (preloadedState) => {
  return createStore(rootReducer, preloadedState, enhancer);
};

export default configureStore;
