import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {Dropdown} from 'react-bootstrap';
import { Tab, Nav } from "react-bootstrap";
//** Import Image */

import {  Sparklines,  SparklinesLine,  } from "react-sparklines";
import Apexspline from './Apexspline';
import ApexchartsNegPost from './ApexNagetivePosative';
import ApexchartsNegPost1 from './ApexNagetivePosative1';

import { useDispatch, useSelector } from "react-redux";
import { getSalesThunk, getTodaySalesThunk, getTopSellingItemsThunk } from "../../../../store/restaurants";

const sampleData1 = [8, 7, 6, 3, 2, 4, 6, 8, 12, 6, 12, 13, 10, 18, 14, 24, 16, 12, 19, 21, 16, 14, 24, 21, 13, 15, 27, 29, 21, 11, 14, 19, 21, 17,];
const sampleData2 = [19, 21, 16, 14, 24, 21, 13, 15, 27, 29, 21, 11, 14, 19, 21, 17, 12, 6, 12, 13, 10, 18, 14, 24, 16, 12, 8, 7, 6, 3, 2, 7, 6, 8,];
const sampleData3 = [8, 7, 6, 3, 2, 4, 6, 8, 10, 6, 12, 15, 13, 15, 14, 13, 21, 11, 14, 10, 21, 10, 13, 10, 12, 14, 16, 14, 12, 10, 9, 8, 4, 1,];
const sampleData4 = [20, 18, 16, 12, 8, 10, 13, 15, 12, 6, 12, 13, 10, 18, 14, 16, 17, 15, 19, 16, 16, 14, 18, 21, 13, 15, 18, 17, 21, 11, 14, 19, 21, 17,];
const tabData = [
	{
		name: "Home",
		content:
		<ApexchartsNegPost1 />,
	},
	{
		name: "Profile",
		content:
		<ApexchartsNegPost />,
	},
	{
		name: "Contact",
		content:
		<ApexchartsNegPost />,
	},

	{
		name: "Message",
		content:
		<ApexchartsNegPost />,
	},
];

const Home = () => {
	const dispatch = useDispatch()
	const id = useSelector(state => state.session.user);
	const topSellingItems = useSelector(state => state.restaurant.topSellingItems);
	const sales = useSelector(state => state.restaurant.sales);
	const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [loading, setLoading] = useState(false); // Add loading state
    const [filter, setFilter] = useState("all"); // "all", "pickup", or "delivery"
	const today_sales = useSelector(state => state.restaurant.today_sales)

	useEffect(() => { 
		dispatch(getTodaySalesThunk(id?.restaurant_id))
	}, [dispatch])
    useEffect(() => {
        setLoading(true); // Set loading to true when data fetching begins
		dispatch(getTopSellingItemsThunk(id?.restaurant_id, startDate, endDate));
        dispatch(getSalesThunk(id?.restaurant_id, startDate, endDate, filter))
            .then(() => setLoading(false)) // Set loading to false when data fetching completes
            .catch(() => setLoading(false)); // Also set loading to false on error
    }, [dispatch,id?.restaurant_id , startDate, endDate, filter]);

    const handleStartDateChange = (event) => {
        setStartDate(event.target.value);
    };

    const handleEndDateChange = (event) => {
        setEndDate(event.target.value);
    };

	return (
      <Fragment>
         <div className="row">
			<div className="col-xl-4 col-lg-4 col-sm-6">
				<div className="card overflow-hidden">
					<div className="card-header media border-0 pb-0">
						<div className="media-body">
							<h2 className="text-black">{today_sales?.totalSales}</h2>
							<p className="mb-0 text-black">Today's Sales</p>
						</div>
						<svg width="20" height="36" viewBox="0 0 20 36" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M19.08 24.36C19.08 25.64 18.76 26.8667 18.12 28.04C17.48 29.1867 16.5333 30.1467 15.28 30.92C14.0533 31.6933 12.5733 32.1333 10.84 32.24V35.48H8.68V32.24C6.25333 32.0267 4.28 31.2533 2.76 29.92C1.24 28.56 0.466667 26.84 0.44 24.76H4.32C4.42667 25.88 4.84 26.8533 5.56 27.68C6.30667 28.5067 7.34667 29.0267 8.68 29.24V19.24C6.89333 18.7867 5.45333 18.32 4.36 17.84C3.26667 17.36 2.33333 16.6133 1.56 15.6C0.786667 14.5867 0.4 13.2267 0.4 11.52C0.4 9.36 1.14667 7.57333 2.64 6.16C4.16 4.74666 6.17333 3.96 8.68 3.8V0.479998H10.84V3.8C13.1067 3.98667 14.9333 4.72 16.32 6C17.7067 7.25333 18.5067 8.89333 18.72 10.92H14.84C14.7067 9.98667 14.2933 9.14667 13.6 8.4C12.9067 7.62667 11.9867 7.12 10.84 6.88V16.64C12.6 17.0933 14.0267 17.56 15.12 18.04C16.24 18.4933 17.1733 19.2267 17.92 20.24C18.6933 21.2533 19.08 22.6267 19.08 24.36ZM4.12 11.32C4.12 12.6267 4.50667 13.6267 5.28 14.32C6.05333 15.0133 7.18667 15.5867 8.68 16.04V6.8C7.29333 6.93333 6.18667 7.38667 5.36 8.16C4.53333 8.90667 4.12 9.96 4.12 11.32ZM10.84 29.28C12.28 29.12 13.4 28.6 14.2 27.72C15.0267 26.84 15.44 25.7867 15.44 24.56C15.44 23.2533 15.04 22.2533 14.24 21.56C13.44 20.84 12.3067 20.2667 10.84 19.84V29.28Z" fill="#EA4989"/>
						</svg>
					</div>
					<div className="card-body  p-0">						
						<Sparklines data={sampleData4}>
							<SparklinesLine style={{ strokeWidth: 2}} color="#ea4989" />
						</Sparklines>						
					</div>
				</div>	
			</div>
			<div className="col-xl-4 col-lg-4 col-sm-6">
				<div className="card overflow-hidden">
					<div className="card-header media border-0 pb-0">
						<div className="media-body">
							<h2 className="text-black">{today_sales?.subtotal}</h2>
							<p className="mb-0 text-black">Today's Subtotal</p>
						</div>
						<svg width="20" height="36" viewBox="0 0 20 36" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M19.08 24.36C19.08 25.64 18.76 26.8667 18.12 28.04C17.48 29.1867 16.5333 30.1467 15.28 30.92C14.0533 31.6933 12.5733 32.1333 10.84 32.24V35.48H8.68V32.24C6.25333 32.0267 4.28 31.2533 2.76 29.92C1.24 28.56 0.466667 26.84 0.44 24.76H4.32C4.42667 25.88 4.84 26.8533 5.56 27.68C6.30667 28.5067 7.34667 29.0267 8.68 29.24V19.24C6.89333 18.7867 5.45333 18.32 4.36 17.84C3.26667 17.36 2.33333 16.6133 1.56 15.6C0.786667 14.5867 0.4 13.2267 0.4 11.52C0.4 9.36 1.14667 7.57333 2.64 6.16C4.16 4.74666 6.17333 3.96 8.68 3.8V0.479998H10.84V3.8C13.1067 3.98667 14.9333 4.72 16.32 6C17.7067 7.25333 18.5067 8.89333 18.72 10.92H14.84C14.7067 9.98667 14.2933 9.14667 13.6 8.4C12.9067 7.62667 11.9867 7.12 10.84 6.88V16.64C12.6 17.0933 14.0267 17.56 15.12 18.04C16.24 18.4933 17.1733 19.2267 17.92 20.24C18.6933 21.2533 19.08 22.6267 19.08 24.36ZM4.12 11.32C4.12 12.6267 4.50667 13.6267 5.28 14.32C6.05333 15.0133 7.18667 15.5867 8.68 16.04V6.8C7.29333 6.93333 6.18667 7.38667 5.36 8.16C4.53333 8.90667 4.12 9.96 4.12 11.32ZM10.84 29.28C12.28 29.12 13.4 28.6 14.2 27.72C15.0267 26.84 15.44 25.7867 15.44 24.56C15.44 23.2533 15.04 22.2533 14.24 21.56C13.44 20.84 12.3067 20.2667 10.84 19.84V29.28Z" fill="#EA4989"/>
						</svg>
					</div>
					<div className="card-body  p-0">						
						<Sparklines data={sampleData4}>
							<SparklinesLine style={{ strokeWidth: 2}} color="#ea4989" />
						</Sparklines>						
					</div>
				</div>	
			</div>
			<div className="col-xl-4 col-lg-4 col-sm-6">
				<div className="card overflow-hidden">
					<div className="card-header media border-0 pb-0">
						<div className="media-body">
							<h2 className="text-black">{today_sales?.totalTax}</h2>
							<p className="mb-0 text-black">Today's Tax</p>
						</div>
						<svg width="20" height="36" viewBox="0 0 20 36" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M19.08 24.36C19.08 25.64 2222 26.8667 18.12 28.04C17.48 29.1867 16.5333 30.1467 15.28 30.92C14.0533 31.6933 12.5733 32.1333 10.84 32.24V35.48H8.68V32.24C6.25333 32.0267 4.28 31.2533 2.76 29.92C1.24 28.56 0.466667 26.84 0.44 24.76H4.32C4.42667 25.88 4.84 26.8533 5.56 27.68C6.30667 28.5067 7.34667 29.0267 8.68 29.24V19.24C6.89333 18.7867 5.45333 18.32 4.36 17.84C3.26667 17.36 2.33333 16.6133 1.56 15.6C0.786667 14.5867 0.4 13.2267 0.4 11.52C0.4 9.36 1.14667 7.57333 2.64 6.16C4.16 4.74666 6.17333 3.96 8.68 3.8V0.479998H10.84V3.8C13.1067 3.98667 14.9333 4.72 16.32 6C17.7067 7.25333 18.5067 8.89333 18.72 10.92H14.84C14.7067 9.98667 14.2933 9.14667 13.6 8.4C12.9067 7.62667 11.9867 7.12 10.84 6.88V16.64C12.6 17.0933 14.0267 17.56 15.12 18.04C16.24 18.4933 17.1733 19.2267 17.92 20.24C18.6933 21.2533 19.08 22.6267 19.08 24.36ZM4.12 11.32C4.12 12.6267 4.50667 13.6267 5.28 14.32C6.05333 15.0133 7.18667 15.5867 8.68 16.04V6.8C7.29333 6.93333 6.18667 7.38667 5.36 8.16C4.53333 8.90667 4.12 9.96 4.12 11.32ZM10.84 29.28C12.28 29.12 13.4 28.6 14.2 27.72C15.0267 26.84 15.44 25.7867 15.44 24.56C15.44 23.2533 15.04 22.2533 14.24 21.56C13.44 20.84 12.3067 20.2667 10.84 19.84V29.28Z" fill="#EA4989"/>
						</svg>
					</div>
					<div className="card-body  p-0">						
						<Sparklines data={sampleData4}>
							<SparklinesLine style={{ strokeWidth: 2}} color="#ea4989" />
						</Sparklines>						
					</div>
				</div>	
			</div>
			<div className="col-xl-4 col-lg-4 col-sm-6">
				<div className="card overflow-hidden">
					<div className="card-header media border-0 pb-0">
						<div className="media-body">
							<h2 className="text-black">{today_sales?.totalTips}</h2>
							<p className="mb-0 text-black">Tips Earning</p>
						</div>
						<svg width="20" height="36" viewBox="0 0 20 36" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M19.08 24.36C19.08 25.64 18.76 26.8667 18.12 28.04C17.48 29.2222 16.5333 30.1467 15.28 30.92C14.0533 31.6933 12.5733 32.1333 10.84 32.24V35.48H8.68V32.24C6.25333 32.0267 4.28 31.2533 2.76 29.92C1.24 28.56 0.466667 26.84 0.44 24.76H4.32C4.42667 25.88 4.84 26.8533 5.56 27.68C6.30667 28.5067 7.34667 29.0267 8.68 29.24V19.24C6.89333 18.7867 5.45333 18.32 4.36 17.84C3.26667 17.36 2.33333 16.6133 1.56 15.6C0.786667 14.5867 0.4 13.2267 0.4 11.52C0.4 9.36 1.14667 7.57333 2.64 6.16C4.16 4.74666 6.17333 3.96 8.68 3.8V0.479998H10.84V3.8C13.1067 3.98667 14.9333 4.72 16.32 6C17.7067 7.25333 18.5067 8.89333 18.72 10.92H14.84C14.7067 9.98667 14.2933 9.14667 13.6 8.4C12.9067 7.62667 11.9867 7.12 10.84 6.88V16.64C12.6 17.0933 14.0267 17.56 15.12 18.04C16.24 18.4933 17.1733 19.2267 17.92 20.24C18.6933 21.2533 19.08 22.6267 19.08 24.36ZM4.12 11.32C4.12 12.6267 4.50667 13.6267 5.28 14.32C6.05333 15.0133 7.18667 15.5867 8.68 16.04V6.8C7.29333 6.93333 6.18667 7.38667 5.36 8.16C4.53333 8.90667 4.12 9.96 4.12 11.32ZM10.84 29.28C12.28 29.12 13.4 28.6 14.2 27.72C15.0267 26.84 15.44 25.7867 15.44 24.56C15.44 23.2533 15.04 22.2533 14.24 21.56C13.44 20.84 12.3067 20.2667 10.84 19.84V29.28Z" fill="#EA4989"/>
						</svg>
					</div>
					<div className="card-body p-0">					
						<Sparklines data={sampleData1}>
							<SparklinesLine style={{ strokeWidth: 2}}  color="#ea4989" />
						</Sparklines>					
					</div>
				</div>
			</div>
			<div className="col-xl-4 col-lg-4 col-sm-6">
				<div className="card overflow-hidden">
					<div className="card-header media border-0 pb-0">
						<div className="media-body">
							<h2 className="text-black">{today_sales?.deliveryOrders}</h2>
							<p className="mb-0 text-black">Delivery Orders</p>
						</div>
						<svg width="32" height="31" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M4 30.5H22.75C23.7442 30.4989 24.6974 30.1035 25.4004 29.4004C26.1035 28.6974 26.4989 27.7442 26.5 26.75V16.75C26.5 16.4185 26.3683 16.1005 26.1339 15.8661C25.8995 15.6317 25.5815 15.5 25.25 15.5C24.9185 15.5 24.6005 15.6317 24.3661 15.8661C24.1317 16.1005 24 16.4185 24 16.75V26.75C23.9997 27.0814 23.8679 27.3992 23.6336 27.6336C23.3992 27.8679 23.0814 27.9997 22.75 28H4C3.66857 27.9997 3.3508 27.8679 3.11645 27.6336C2.88209 27.3992 2.7503 27.0814 2.75 26.75V9.25C2.7503 8.91857 2.88209 8.6008 3.11645 8.36645C3.3508 8.13209 3.66857 8.0003 4 8H15.25C15.5815 8 15.8995 7.8683 16.1339 7.63388C16.3683 7.39946 16.5 7.08152 16.5 6.75C16.5 6.41848 16.3683 6.10054 16.1339 5.86612C15.8995 5.6317 15.5815 5.5 15.25 5.5H4C3.00577 5.50109 2.05258 5.89653 1.34956 6.59956C0.646531 7.30258 0.251092 8.25577 0.25 9.25V26.75C0.251092 27.7442 0.646531 28.6974 1.34956 29.4004C2.05258 30.1035 3.00577 30.4989 4 30.5Z" fill="#EA4989"/>
							<path d="M25.25 0.5C24.0138 0.5 22.8055 0.866556 21.7777 1.55331C20.7498 2.24007 19.9488 3.21619 19.4757 4.35823C19.0027 5.50027 18.8789 6.75693 19.1201 7.96931C19.3612 9.1817 19.9565 10.2953 20.8306 11.1694C21.7046 12.0435 22.8183 12.6388 24.0307 12.8799C25.243 13.1211 26.4997 12.9973 27.6417 12.5242C28.7838 12.0512 29.7599 11.2501 30.4466 10.2223C31.1334 9.19451 31.5 7.98613 31.5 6.75C31.498 5.093 30.8389 3.50442 29.6672 2.33274C28.4955 1.16106 26.907 0.501952 25.25 0.5ZM25.25 10.5C24.5083 10.5 23.7833 10.2801 23.1666 9.86801C22.5499 9.45596 22.0692 8.87029 21.7854 8.18506C21.5016 7.49984 21.4273 6.74584 21.572 6.01841C21.7167 5.29098 22.0739 4.6228 22.5983 4.09835C23.1228 3.5739 23.7909 3.21675 24.5184 3.07206C25.2458 2.92736 25.9998 3.00162 26.685 3.28545C27.3702 3.56928 27.9559 4.04993 28.368 4.66661C28.78 5.2833 29 6.00832 29 6.75C28.9989 7.74423 28.6034 8.69742 27.9004 9.40044C27.1974 10.1035 26.2442 10.4989 25.25 10.5Z" fill="#EA4989"/>
							<path d="M6.5 13H12.75C13.0815 13 13.3995 12.8683 13.6339 12.6339C13.8683 12.3995 14 12.0815 14 11.75C14 11.4185 13.8683 11.1005 13.6339 10.8661C13.3995 10.6317 13.0815 10.5 12.75 10.5H6.5C6.16848 10.5 5.85054 10.6317 5.61612 10.8661C5.3817 11.1005 5.25 11.4185 5.25 11.75C5.25 12.0815 5.3817 12.3995 5.61612 12.6339C5.85054 12.8683 6.16848 13 6.5 13Z" fill="#EA4989"/>
							<path d="M5.25 16.75C5.25 17.0815 5.3817 17.3995 5.61612 17.6339C5.85054 17.8683 6.16848 18 6.5 18H17.75C18.0815 18 18.3995 17.8683 18.6339 17.6339C18.8683 17.3995 19 17.0815 19 16.75C19 16.4185 18.8683 16.1005 18.6339 15.8661C18.3995 15.6317 18.0815 15.5 17.75 15.5H6.5C6.16848 15.5 5.85054 15.6317 5.61612 15.8661C5.3817 16.1005 5.25 16.4185 5.25 16.75Z" fill="#EA4989"/>
						</svg>
					</div>
					<div className="card-body p-0">					
						<Sparklines data={sampleData1}>
							<SparklinesLine style={{ strokeWidth: 2}}  color="#ea4989" />
						</Sparklines>					
					</div>
				</div>
			</div>
			<div className="col-xl-4 col-lg-4 col-sm-6">
				<div className="card overflow-hidden">
					<div className="card-header media border-0 pb-0">
						<div className="media-body">
							<h2 className="text-black">{today_sales?.pickupOrders}</h2>
							<p className="mb-0 text-black">Pickup Orders</p>
						</div>
						<svg width="32" height="31" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M4 30.5H22.75C23.7442 30.4989 24.6974 30.1035 25.4004 29.4004C26.1035 28.6974 26.4989 27.7442 26.5 26.75V16.75C26.5 16.4185 26.3683 16.1005 26.1339 15.8661C25.8995 15.6317 25.5815 15.5 25.25 15.5C24.9185 15.5 24.6005 15.6317 24.3661 15.8661C24.1317 16.1005 24 16.4185 24 16.75V26.75C23.9997 27.0814 23.8679 27.3992 23.6336 27.6336C23.3992 27.8679 23.0814 27.9997 22.75 28H4C3.66857 27.9997 3.3508 27.8679 3.11645 27.6336C2.88209 27.3992 2.7503 27.0814 2.75 26.75V9.25C2.7503 8.91857 2.88209 8.6008 3.11645 8.36645C3.3508 8.13209 3.66857 8.0003 4 8H15.25C15.5815 8 15.8995 7.8683 16.1339 7.63388C16.3683 7.39946 16.5 7.08152 16.5 6.75C16.5 6.41848 16.3683 6.10054 16.1339 5.86612C15.8995 5.6317 15.5815 5.5 15.25 5.5H4C3.00577 5.50109 2.05258 5.89653 1.34956 6.59956C0.646531 7.30258 0.251092 8.25577 0.25 9.25V26.75C0.251092 27.7442 0.646531 28.6974 1.34956 29.4004C2.05258 30.1035 3.00577 30.4989 4 30.5Z" fill="#EA4989"/>
							<path d="M25.25 0.5C24.0138 0.5 22.8055 0.866556 21.7777 1.55331C20.7498 2.24007 19.9488 3.21619 19.4757 4.35823C19.0027 5.50027 18.8789 6.75693 19.1201 7.96931C19.3612 9.1817 19.9565 10.2953 20.8306 11.1694C21.7046 12.0435 22.8183 12.6388 24.0307 12.8799C25.243 13.1211 26.4997 12.9973 27.6417 12.5242C28.7838 12.0512 29.7599 11.2501 30.4466 10.2223C31.1334 9.19451 31.5 7.98613 31.5 6.75C31.498 5.093 30.8389 3.50442 29.6672 2.33274C28.4955 1.16106 26.907 0.501952 25.25 0.5ZM25.25 10.5C24.5083 10.5 23.7833 10.2801 23.1666 9.86801C22.5499 9.45596 22.0692 8.87029 21.7854 8.18506C21.5016 7.49984 21.4273 6.74584 21.572 6.01841C21.7167 5.29098 22.0739 4.6228 22.5983 4.09835C23.1228 3.5739 23.7909 3.21675 24.5184 3.07206C25.2458 2.92736 25.9998 3.00162 26.685 3.28545C27.3702 3.56928 27.9559 4.04993 28.368 4.66661C28.78 5.2833 29 6.00832 29 6.75C28.9989 7.74423 28.6034 8.69742 27.9004 9.40044C27.1974 10.1035 26.2442 10.4989 25.25 10.5Z" fill="#EA4989"/>
							<path d="M6.5 13H12.75C13.0815 13 13.3995 12.8683 13.6339 12.6339C13.8683 12.3995 14 12.0815 14 11.75C14 11.4185 13.8683 11.1005 13.6339 10.8661C13.3995 10.6317 13.0815 10.5 12.75 10.5H6.5C6.16848 10.5 5.85054 10.6317 5.61612 10.8661C5.3817 11.1005 5.25 11.4185 5.25 11.75C5.25 12.0815 5.3817 12.3995 5.61612 12.6339C5.85054 12.8683 6.16848 13 6.5 13Z" fill="#EA4989"/>
							<path d="M5.25 16.75C5.25 17.0815 5.3817 17.3995 5.61612 17.6339C5.85054 17.8683 6.16848 18 6.5 18H17.75C18.0815 18 18.3995 17.8683 18.6339 17.6339C18.8683 17.3995 19 17.0815 19 16.75C19 16.4185 18.8683 16.1005 18.6339 15.8661C18.3995 15.6317 18.0815 15.5 17.75 15.5H6.5C6.16848 15.5 5.85054 15.6317 5.61612 15.8661C5.3817 16.1005 5.25 16.4185 5.25 16.75Z" fill="#EA4989"/>
						</svg>
					</div>
					<div className="card-body  p-0">						
						<Sparklines data={sampleData2}>
							<SparklinesLine style={{ strokeWidth: 2}} color="#ea4989" />
						</Sparklines>						
					</div>
				</div>
			</div>

			
			<div className="col-xl-12 col-xxl-12">
					<div className="row">
						
<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
    <div className="card">
        <div className="card-header pb-0 border-0">
            <div className="d-flex justify-content-between align-items-center">
                <div>
                    <h4 className="card-title mb-0">Sales Report</h4>
                    <p className="fs-13 mb-0">Select Date Range:</p>
                </div>
            </div>
                <div className="d-flex">
                    <input type="date" className="form-control mr-2" value={startDate} onChange={handleStartDateChange} />
                    <input type="date" className="form-control" value={endDate} onChange={handleEndDateChange} />
                </div>
        </div>
        <div className="card-body">
		<div className="row">
            <div className="card text-white ">
                <ul className="list-group list-group-flush">
                    <li className="list-group-item d-flex justify-content-between">
                        <span className="mb-0">Selected Dates:</span>
                        {startDate && endDate ? (
                            <strong>Start: {startDate} End: {endDate}</strong>
                        ) : (
                            <strong>Default (Today)</strong>
                        )}
                    </li>

                    {/* Toggle Switch for Order Type */}
                    <li className="list-group-item d-flex justify-content-between">
                        <span className="mb-0">Filter Orders:</span>
                        <div>
                            <button 
                                className={`btn btn-sm ${filter === "all" ? "btn-light" : "btn-secondary"}`} 
                                onClick={() => setFilter("all")}
                            >
                                All Orders
                            </button>
                            <button 
                                className={`btn btn-sm mx-1 ${filter === "pickup" ? "btn-light" : "btn-secondary"}`} 
                                onClick={() => setFilter("pickup")}
                            >
                                Pickup
                            </button>
                            <button 
                                className={`btn btn-sm ${filter === "delivery" ? "btn-light" : "btn-secondary"}`} 
                                onClick={() => setFilter("delivery")}
                            >
                                Delivery
                            </button>
                        </div>
                    </li>

                    {loading ? (
                        <strong>Loading...</strong>
                    ) : (
                        <div className="container">
    {/* Sales Overview */}
    <div className="card shadow-lg mb-2">
        <div className="card-header bg-primary text-white text-center">
            <h5 className="mb-0">💰 Sales Overview</h5>
        </div>
        <div className="card-body">
            <div className="row">
                <div className="col-md-6">
                    <li className="list-group-item d-flex justify-content-between">
                        <span>Total Sales:</span>
                        <strong>${sales?.totalSales}</strong>
                    </li>
                </div>
                <div className="col-md-6">
                    <li className="list-group-item d-flex justify-content-between">
                        <span>Subtotal:</span>
                        <strong>${sales?.subtotal}</strong>
                    </li>
                </div>
            </div>
        </div>
    </div>

    {/* Taxes & Tips */}
    <div className="card shadow-lg mb-2">
        <div className="card-header bg-warning text-dark text-center">
            <h5 className="mb-0">💸 Taxes & Tips</h5>
        </div>
        <div className="card-body">
            <div className="row">
                <div className="col-md-6">
                    <li className="list-group-item d-flex justify-content-between">
                        <span>Total Tax:</span>
                        <strong>${sales?.totalTax}</strong>
                    </li>
                </div>
                <div className="col-md-6">
                    <li className="list-group-item d-flex justify-content-between">
                        <span>Total Tips:</span>
                        <strong>${sales?.totalTips}</strong>
                    </li>
                </div>
            </div>
        </div>
    </div>

    {/* Fees Breakdown */}
    <div className="card shadow-lg mb-2">
        <div className="card-header bg-danger text-white text-center">
            <h5 className="mb-0">📉 Fees Breakdown</h5>
        </div>
        <div className="card-body">
            <div className="row">
                <div className="col-md-6">
                    <li className="list-group-item d-flex justify-content-between">
                        <span>Processing Charge:</span>
                        <strong>${sales?.totalProcessingCharge}</strong>
                    </li>
                </div>
                <div className="col-md-6">
                    <li className="list-group-item d-flex justify-content-between">
                        <span>Credit Card Charge:</span>
                        <strong>${sales?.totalCreditCardCharge}</strong>
                    </li>
                </div>
                <div className="col-md-6">
                    <li className="list-group-item d-flex justify-content-between">
                        <span>Commission Fee:</span>
                        <strong>${sales?.totalCommissionFee}</strong>
                    </li>
                </div>
                <div className="col-md-6">
                    <li className="list-group-item d-flex justify-content-between">
                        <span>Delivery Fee:</span>
                        <strong>${sales?.totalDeliveryFee}</strong>
                    </li>
                </div>
                <div className="col-md-6">
                    <li className="list-group-item d-flex justify-content-between">
                        <span>Service Fee:</span>
                        <strong>${sales?.totalServiceFee}</strong>
                    </li>
                </div>
            </div>
        </div>
    </div>

    {/* Order Summary */}
    <div className="card shadow-lg mb-2">
        <div className="card-header bg-info text-white text-center">
            <h5 className="mb-0">📦 Order Summary</h5>
        </div>
        <div className="card-body">
            <div className="row">
                <div className="col-md-6">
                    <li className="list-group-item d-flex justify-content-between">
                        <span>Total Orders:</span>
                        <strong>{sales?.totalOrders}</strong>
                    </li>
                </div>
                <div className="col-md-6">
                    <li className="list-group-item d-flex justify-content-between">
                        <span>Pickup Orders:</span>
                        <strong>{sales?.pickupOrders}</strong>
                    </li>
                </div>
                <div className="col-md-6">
                    <li className="list-group-item d-flex justify-content-between">
                        <span>Delivery Orders:</span>
                        <strong>{sales?.deliveryOrders}</strong>
                    </li>
                </div>
            </div>
        </div>
    </div>

    {/* Payment Summary */}
    <div className="card shadow-lg">
        <div className="card-header bg-success text-white text-center">
            <h5 className="mb-0">💳 Payment Summary</h5>
        </div>
        <div className="card-body">
            <div className="row">
                <div className="col-md-12">
                    <li className="list-group-item d-flex justify-content-between">
                        <span>Total Payment Received:</span>
                        <strong>${sales?.totalPaymentReceive}</strong>
                    </li>
                </div>
            </div>
        </div>
    </div>
</div>

                    )}
                </ul>
            </div>
        </div>
		<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 ">
							<div className="card">
								<div className="card-header border-0">
									<div>
										<h4 className="card-title mb-2">Item Sales</h4>
										<p className="mb-0 fs-14">{startDate && endDate ? (
                            <strong>Viewing: Start: {startDate} End: {endDate} item sales</strong>
                        ) : (
                            <strong>Default (Today) item sales</strong>
                        )} </p>
									</div>
								</div>
								<div className="card-body p-0">
    <div className="widget-media trending-menus">
        <ul className="timeline">
            <div className="row">
                {topSellingItems?.map((item, index) => (
                    <div key={index} className="col-6">
                        <li>
                            <div className="timeline-panel">
                                <div className="media me-4">
                                    <Link to={"/"}>
                                        <img 
                                            alt="" 
                                            width="90" 
                                            src={item.image_url ? item.image_url : 'https://media.istockphoto.com/id/1153595451/vector/cute-cartoon-potato-character-vector-illustration-isolated-on-white-background.jpg?s=612x612&w=0&k=20&c=9ssgBs7dS28etZUvsO-hgR_0SS4Nzb2p-0lfS8QpRbY='} 
                                        />
                                    </Link>
                                    <div className="number">#{index + 1}</div>
                                </div>
                                <div className="media-body">
                                    <h5 className="mb-3 text-black">
                                        <Link to={"/"} className="text-black">{item.name}</Link>
                                    </h5>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <h4 className="mb-0 text-black font-w600">${item.price}</h4>
                                        <p className="mb-0">Sold <strong className="text-black font-w500">{item.quantity}</strong></p>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </div>
                ))}
            </div>
        </ul>
    </div>
</div>

							</div>
				</div>
        </div>



    </div>

</div>





					</div>
				</div>


			{/* <div className="col-xl-6 col-lg-12">
				<div className="card">
					<Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
						<div className="card-header border-0 d-sm-flex d-block">
							<div>
								<h4 className="card-title mb-2">Customer Map</h4>
								<p className="fs-13 mb-0">Lorem ipsum dolor</p>
							</div>
							<div className="card-action card-tabs mt-3 mt-sm-0 mt-3 mt-sm-0 ">
								<Nav as="ul" className="nav nav-tabs ">
								   {tabData.map(
									  (data, i) =>
										 i !== tabData.length - 1 && (
											<Nav.Item as="li" key={i}>
											   <Nav.Link
												  eventKey={data.name.toLowerCase()}
											   >
												  {" "}
												  {i === 1
													 ? "Weekly"
													 : i === 2
													 ? "Today"
													 : "Monthly"}
											   </Nav.Link>
											</Nav.Item>
										)
								   )}
								</Nav>
							</div>
						</div>
						<div className="card-body">
							<Tab.Content className="">
							   {tabData.map(
								  (data, i) =>
									 i !== tabData.length - 1 && (
										<Tab.Pane
										   eventKey={data.name.toLowerCase()}
										   key={i}
										>
										   <div>{data.content}</div>
										   
										</Tab.Pane>
									 )
							   )}
							</Tab.Content>
						</div>
					</Tab.Container>
				</div>
			</div> */}
		</div>
      </Fragment>
   );
};

export default Home;
